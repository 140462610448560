<template>
    <setting-layout :title="$t('pages.user.setting.creator.holiday_mood.title')">
        <v-container>
            <div class="mb-4">
                <p>{{ $t("pages.user.setting.creator.holiday_mood.description_title") }}</p>
                <p>{{ $t("pages.user.setting.creator.holiday_mood.description_details") }}</p>
                <p>{{ $t("pages.user.setting.creator.holiday_mood.description_bottom") }}</p>                
            </div>
            <v-row class="ml-1">
                <v-switch v-model="holidayMood">
                    <template #prepend>
                        <v-label>{{ $t('pages.user.setting.creator.holiday_mood.active_label') }}</v-label>
                    </template>
                </v-switch>
            </v-row>
            <v-row justify="center">
                <gradient-button class="w-full" block @click="save" :loading="$store.state.buttonsLoading.holidayMoodBtn">
                    {{ $t("dialogs.save") }}
                </gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "HolidaMood",

    components: { SettingLayout, GradientButton },

    data: () => ({
        holidayMood: false,
    }),

    computed: {
        ...mapState({
            user: state => state.user.user.data,
        }),
    },

    async mounted() {
        await this.getCurrentUserData().then((res) => {
            this.holidayMood = res.data.on_holiday_mood;
        });
    },

    methods: {
        ...mapActions([
            'saveHolidayMood',
            'getCurrentUserData',
        ]),

        async save() {
            await this.saveHolidayMood({
                model: {
                    on_holiday_mood: this.holidayMood,
                }
            });
        },
    }
};
</script>

<style scoped lang="scss">

</style>
